import { Route, Routes } from 'react-router-dom';
import { Home, Support } from '../components';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<Home />} path="/" />
      <Route element={<Support />} path="/support" />
    </Routes>
  );
};

export default AppRouter;
