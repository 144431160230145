import {
  AppBar,
  Badge,
  Box,
  CssBaseline,
  IconButton,
  ListItemIcon,
  ListItem,
  List,
  Drawer,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  ListItemButton,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from '../styles/theme';
import {
  CloseIcon,
  Closer2Icon,
  CustomerIcon,
  DashboardIcon,
  LogoutIcon,
  MenuIcon,
  SupportIcon,
} from '../assets/icons';
import PanelSidebar from './PanelSidebar';

const drawerWidth = 240;
const PanelLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const path = useLocation();
  const navigate = useNavigate();

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleLogoutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: {
            xs: theme.palette.primary.main,
            sm: theme.palette.background.default,
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
          }}
        >
          {isMobile && (
            <Box sx={{ height: '17px', width: '96px' }}>
              <Closer2Icon />
            </Box>
          )}
          <Box>
            <IconButton onClick={handleMenuClick}>
              <Badge>
                <CustomerIcon fill={!isMobile ? '#222222' : '#FFFFFF'} />
                {!isMobile && (
                  <Typography color="#000" pl={2}>
                    {user?.email}
                  </Typography>
                )}
              </Badge>
            </IconButton>
            <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={Boolean(anchorEl)}>
              <MenuItem onClick={handleLogoutClick}>
                {' '}
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Log Out
              </MenuItem>
            </Menu>
            {isMobile && (
              <IconButton
                aria-label="open drawer"
                color="inherit"
                edge="start"
                onClick={handleMobileMenuToggle}
                style={{ paddingLeft: '13px' }}
              >
                {!mobileMenuOpen ? <MenuIcon /> : <CloseIcon />}
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="bottom"
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            invisible: true,
          },
        }}
        onClose={handleMobileMenuToggle}
        open={mobileMenuOpen}
        PaperProps={{
          style: {
            backgroundColor: '#441078',
            height: `calc(100vh - 54px)`,
            top: 54,
          },
        }}
        sx={{
          '& .MuiDrawer-paper': {
            top: '54px',
          },
        }}
        variant="persistent"
      >
        <List sx={{ paddingLeft: '10px' }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/');
                setMobileMenuOpen(false);
              }}
              style={{
                backgroundColor: 'transparent',
              }}
              sx={{
                backgroundColor:
                  path.pathname === '/' ? 'rgba(255, 255, 255, 0.10)' : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '3px',
                }}
              >
                <DashboardIcon fill={path.pathname === '/' ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname === '/' ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Dashboard
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/support');
                setMobileMenuOpen(false);
              }}
              style={{
                backgroundColor: 'transparent',
              }}
              sx={{
                backgroundColor:
                  path.pathname === '/support'
                    ? 'rgba(255, 255, 255, 0.10)'
                    : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '3px',
                }}
              >
                <SupportIcon fill={path.pathname === '/support' ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname === '/support' ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Support
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box
        aria-label="mailbox folders"
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <PanelSidebar drawerWidth={drawerWidth} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)`, marginTop: 42 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PanelLayout;
