import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Closer2Icon, DashboardIcon, SupportIcon } from '../assets/icons';
import { theme } from '../styles/theme';

interface PanelSidebarProps {
  drawerWidth: number;
}

const PanelSidebar: FC<PanelSidebarProps> = ({ drawerWidth }) => {
  const path = useLocation();
  const navigate = useNavigate();
  return (
    <Drawer
      open
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          backgroundColor: theme.palette.primary.main,
        },
      }}
      variant="permanent"
    >
      <Box>
        <Toolbar style={{ padding: '20px' }}>
          <Closer2Icon />
        </Toolbar>
        <List sx={{ paddingLeft: '10px' }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/')}
              sx={{
                backgroundColor:
                  path.pathname === '/' ? 'rgba(255, 255, 255, 0.10)' : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '3px',
                }}
              >
                <DashboardIcon fill={path.pathname === '/' ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname === '/' ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Dashboard
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/support')}
              sx={{
                backgroundColor:
                  path.pathname === '/support'
                    ? 'rgba(255, 255, 255, 0.10)'
                    : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '3px',
                }}
              >
                <SupportIcon fill={path.pathname === '/support' ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname === '/support' ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Support
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default PanelSidebar;
