import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress } from '@mui/material';
import { AppRouter } from './pages';
import PanelLayout from './layout';
import { GraphQLProvider } from './providers/GraphQLProvider';
import './styles/index.css';

const rolesNamespace = process.env.REACT_APP_ROLES_NAMESPACE || 'https://closer2.io/roles';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading, getIdTokenClaims, logout } = useAuth0();
  const [isRoleVerified, setIsRoleVerified] = useState(false);

  useEffect(() => {
    const getRoleAndRedirectIfNotCustomer = async () => {
      if (isAuthenticated && !isLoading) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const roles = idTokenClaims ? idTokenClaims[rolesNamespace ?? ''] : null;

          if (!roles || !roles.includes('Customer')) {
            await logout({ logoutParams: { returnTo: window.location.origin } });
          } else {
            setIsRoleVerified(true);
          }
        } catch (error) {
          console.error('Error getting the id token claims:', error);
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      }
    };

    getRoleAndRedirectIfNotCustomer();
  }, [isAuthenticated, isLoading, getIdTokenClaims, logout]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || !isRoleVerified) {
    return (
      <Box alignItems="center" display="flex" height="100vh" justifyContent="center" width="100%">
        <CircularProgress size={100} />
      </Box>
    );
  }

  return (
    <GraphQLProvider>
      <PanelLayout>
        <AppRouter />
      </PanelLayout>
    </GraphQLProvider>
  );
}

export default App;
